@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.topbar {
  padding: 15px var(--content-x-padding);
  @include media-breakpoint-down(md) {
    padding: 10px;
  }
  background-color: var(--color-darkbg);
  color: var(--color-darkbg-text);
  .show-notifications {
    padding-top: 5px;
    &:hover {
      cursor: pointer;
    }
  }
  button.logout {
    border-radius: 0;
    padding: 0;
    color: var(--color-darkbg-text);
  }
}
