.user-badge {
  .info {
    .name {
      color: #19155e;
      font-size: 1.5rem;
      span {
        font-size: 1rem;
      }
    }
    .title {
      font-size: 1.125rem;
    }
  }
}
