.password-group {
  .input-group-text {
    background-color: transparent;
    border-radius: 10px;
    span {
      font-size: 1.3rem;
      color: black;
      &:hover {
        cursor: pointer;
      }
    }
  }
  input {
    border-right: none;
  }
}
