.subscribe-btn.btn-primary {
  width: 170px;
  text-align: center;
  justify-content: center;
  .spinner-border {
    width: 1rem;
    height: 1rem;
    display: inline-block;
  }
  span {
    display: inline-block;
  }
  &.checked {
    color: white;
    &:hover {
      background-color: transparent !important;
      color: var(--color-primary);
      &:after {
        content: "Unsubscribe";
      }
      span {
        display: none;
      }
    }
  }
}
