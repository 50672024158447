.error {
  color: var(--color-danger);
  label {
    border: 1px solid var(--color-danger);
  }
}

form.beacon {
  .row {
    justify-content: center !important;
  }
}
