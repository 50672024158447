@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.asset-images {
  width: 490px;
  @include media-breakpoint-down(sm) {
    width: auto;
    max-width: 100%;
  }
  .preview {
    width: 490px;
    height: 440px;
    background-size: cover;
    background-position: center center;
  }
  .thumbnails {
    gap: 40px;
    .thumbnail {
      cursor: pointer;
      background-size: cover;
      background-position: center center;
    }
  }
}
