@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.auth {
  background-color: #f0eeee;
  .container {
    @include media-breakpoint-up(md) {
      max-width: 750px;
    }
    padding-top: 30px;
    .alert {
      a {
        color: var(--color-primary);
        text-decoration: underline;
        font-size: inherit;
        &:hover {
          text-decoration: none;
        }
      }
    }
    h1 {
      font-weight: 600;
    }
    .signup-button {
      display: inline-block;
      padding: 8px 20px;
      font-size: 14px;
      color: var(--color-primary);
      background-color: #ffffff;
      border: 1px solid var(--color-primary);
      border-radius: 4px;
      text-decoration: none;
      transition: background-color 0.3s, color 0.3s;
    }

    .signup-button:hover {
      background-color: var(--color-primary);
      color: #ffffff;
      text-decoration: none !important;
    }
    form {
      label {
        text-transform: uppercase;
        color: #54545b;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.25ch;
        margin-bottom: 15px;
      }
      input[type="text"],
      input[type="password"] {
        border-radius: 10px;
        padding: 0 15px;
        font-size: 1rem;
        font-weight: normal;
      }
      button {
        font-size: 1.125rem;
        font-weight: 600;
      }
      a {
        color: #54545b;
        font-weight: bold;
        text-decoration: none;
        font-size: 0.875rem;
        &:hover {
          text-decoration: underline;
        }
      }
      .remember-me label {
        text-transform: none;
        font-weight: 600;
        font-size: 1rem;
        margin-left: 10px;
      }
      input[type="checkbox"] {
        transform: scale(1.3);
      }
    }
    p {
      font-size: 0.5625rem;
      a {
        color: #1430c7;
        &:hover {
          text-decoration: none;
        }
      }
      &.copyright {
        margin-top: 60px;
      }
    }
  }
}
