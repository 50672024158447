.pricing {
  h2 {
    font-size: 2.5rem;
    color: black;
    margin-bottom: 15px;
  }
  .plan-switcher {
    button {
      border-radius: 15px;
      background-color: #e9e6fc;
      color: black;
      border: none;
      font-weight: 500;
      padding: 15px 40px;
      &.active {
        background-color: var(--color-primary);
        color: white;
      }
    }
  }
  .plan {
    border-radius: 30px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px 25px;
    height: 100%;
    h4 {
      font-size: 1rem;
      color: #000;
    }
    .cost {
      strong {
        font-size: 2.5rem;
      }
    }
    ul.features {
      list-style-type: none;
      border-top: 1px solid #d3e0f8;
      padding: 0;
      li {
        border-bottom: 1px solid #d3e0f8;
        padding: 7px 0;
      }
    }
    button {
      margin-top: 40px;
      width: 100%;
      padding: 15px;
      border-radius: 15px;
    }
  }
}
