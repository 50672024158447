.image-uploader {
  h4 {
    font-size: 1rem;
    color: #000;
    padding: 30px 0;
  }
  label {
    background-size: cover;
    border-radius: 30px;
    background-position: center center;
    &:hover {
      cursor: pointer;
    }
  }
}
