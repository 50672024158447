@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

.search-box-container {
  width: 300px;
  @include media-breakpoint-down(md) {
    width: 150px !important;
  }
  .search-box__control {
    border-radius: 19px;
    padding-left: 5px;
    width: 300px !important;
    @include media-breakpoint-down(md) {
      width: 150px !important;
    }
    outline: none;
    background-color: transparent;
    border-color: #c5d2ea;
    color: #c5d2ea;
    font-weight: bold;
    .placeholder-text {
      color: #c5d2ea;
    }
    .search-box__single-value {
      color: #c5d2ea;
    }
    .search-box__value-container {
      display: flex;
    }
    .search-box__input-container {
      color: #c5d2ea !important;
      .search-box__input {
        @include placeholder {
          color: #c5d2ea !important;
        }
      }
    }
  }
  .search-box__menu {
    .search-box__menu-list {
      padding: 10px;
      .search-box__option {
        .item {
          font-size: 0.875rem;
          font-weight: bold;
          color: black;
        }
        .metadata {
          color: #474747;
          font-size: 12px;
        }
      }
    }
  }
  .search-box__control--is-focused,
  .search-box__control--menu-is-open,
  .search-box__control:hover {
    outline: none;
    box-shadow: none;
    border-color: #c5d2ea;
    background-color: transparent;
  }
}
