.box {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 10px;
  min-height: 136px;
  &.stat-box {
    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }
  &.grid-box {
    min-height: auto;
    padding-bottom: 0 !important;
    .data-grid {
      margin-bottom: 0;
      padding-bottom: 2px !important;
    }
  }
  .header {
    min-height: 45px;
    margin-bottom: 10px;
  }
  .title {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: bold;
    color: #636566;
  }
  .content {
    .value {
      display: block;
      font-size: 1.75rem;
      font-weight: bold;
      margin-top: -15px;
    }
    .data-grid {
      padding: 15px;
      h3 {
        font-size: 1.5rem;
        color: #000;
      }
    }
  }
}
