.org-badge {
  padding-bottom: 20px;
  .name {
    font-size: 1.4rem;
    line-height: 32px;
    font-weight: bold;
  }
  .id {
    font-size: 0.9rem;
    color: #4d4d54;
  }
  &.mini {
    padding: 0;
    .name {
      font-size: 0.875rem;
      font-weight: normal;
      margin-top: 5px;
    }
  }
  &.no-logo {
    .name {
      text-decoration: underline;
      font-size: 1.5rem;
    }
    .id {
      font-size: 0.875rem;
      font-weight: normal;
      margin-top: 5px;
    }
  }
  .creator {
    h4 {
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .name,
    .id {
      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 1.1rem;
      font-weight: normal;
      color: #4d4d54;
    }
    button {
      background-color: var(--color-primary);
      color: white;
    }
  }
}

.dashboard {
  .org-badge {
    .name {
      font-size: 2rem;
      line-height: 32px;
      font-weight: bold;
    }
  }
}
