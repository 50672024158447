.first-visit-modal {
  .modal-dialog {
    max-width: 600px;
    margin-top: 40px;
  }

  .modal-content {
    border-radius: 16px;
    overflow: hidden;
    padding: 32px;
    border: none;
  }

  .video-container {
    .video-player {
      width: 100%;
      height: 290px;
      background-image: url("../../assets/images/tutorial_thumbnail.jpg");
      background-size: cover;
      background-position: center;
      border-radius: 16px;
      cursor: pointer;

      .video-placeholder {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        gap: 16px;

        .play-button {
          font-size: 48px;
          opacity: 1;
        }

        p {
          font-size: 1rem;
          margin: 0;
          opacity: 0.8;
        }
      }

      &:hover {
        .video-placeholder {
          .play-button,
          u p {
            opacity: 0.8;
          }
        }
      }
    }

    .welcome-text {
      color: #4e5361;
      font-size: 0.875rem;
      h2 {
        font-size: 1.625rem;
        font-weight: 600;
        color: #2c2c3d;
        margin-bottom: 16px;
      }

      p {
        line-height: 1.5;
      }

      ul {
        padding-left: 0;
        list-style: disc;
        margin: 12px 0 0 24px;

        li {
          line-height: 2;
          color: #4e5361;
        }
      }

      .pro-tip {
        margin-top: 12px;
        margin-bottom: 8px;
      }

      .help-text {
        margin-bottom: 0;
      }
    }

    .video-controls {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .btn {
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 8px;
        height: 43px;
      }

      .btn-link {
        background: #2c407c;
        color: #ffffff;
        text-decoration: none;
        font-weight: normal;
        align-self: stretch;
        font-size: 1rem;

        &:hover {
          background: darken(#2c407c, 5%);
          color: #ffffff;
        }
      }

      .btn-primary {
        background: #ffffff;
        border: 1px solid #4e5361;
        color: #4e5361;
        font-weight: normal;
        font-size: 1rem;
        box-sizing: border-box;
        align-self: stretch;
        transition: all 0.2s ease;

        &:hover {
          background: #f0f0f0;
          color: #4e5361;
          border-color: #4e5361;
        }
      }
    }
  }
}
