.step-progressbar {
  list-style: none;
  display: flex;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 500;
  .step {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    position: relative;
    color: fade-out($color: #000000, $amount: 0.5);
    img {
      margin-right: 8px;
      opacity: 0.5;
    }
    &:before {
      width: 16px;
      height: 16px;
      content: "";
      align-self: center;
      border: 2px solid #c4c4c4;
      background-color: #fff;
      color: #fff;
      border-radius: 100%;
      line-height: 25px;
      margin-top: 5px;
      margin-bottom: 5px;
      z-index: 1;
    }

    &:after {
      height: 2px;
      width: 100%;
      content: "";
      background: #c4c4c4;
      position: absolute;
      top: 0.8em;
      left: 50%;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    &.active {
      &:before {
        background-color: #fff;
        border: 2px solid #69a509;
      }
    }
    &.rejected {
      &:before {
        color: red;
        background-image: url("../../assets/images/icons/progressbar/canceled.png");
        width: 30px;
        height: 30px;
        border: none;
        margin-top: -3px;
      }
      color: #000;
      img {
        display: none;
      }
    }
    &.complete {
      color: #000;
      font-weight: 500;
      img {
        opacity: 1;
      }
      &:before {
        width: 25px;
        height: 25px;
        background-color: #69a509;
        margin-top: 0;
        margin-bottom: 1px;
        content: "✔";
        font-size: 1.1rem;
        font-weight: normal;
        line-height: 1.7rem;
        border: 0;
      }
      &:after {
        background-color: #69a509;
      }
    }
  }
}
