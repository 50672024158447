.community-request-activity-grid {
  td {
    .status {
      &::before {
        content: "";
        display: inline-block;
        margin-right: 5px;
        width: 10px;
        height: 10px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
      }

      &.published {
        &::before {
          background-color: skyblue;
        }
      }

      &.completed {
        &::before {
          background-color: #305c02;
        }
      }
      &.accepted {
        &::before {
          background-color: #ffc832;
        }
      }
      &.rejected {
        &::before {
          background-color: #931420;
        }
      }
    }
  }
}
