.custom-upload-file-chooser {
  display: none;
}

.error {
  color: var(--color-danger);
  label {
    border: 1px solid var(--color-danger);
  }
}

form.community-request {
  .row {
    justify-content: center !important;
  }
}
