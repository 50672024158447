.tutorial-modal {
  .modal-dialog {
    max-width: 800px;
    margin-top: 40px;
  }

  .modal-content {
    border-radius: 16px;
    overflow: hidden;
    padding: 32px;
    border: none;
  }

  .video-container {
    .video-player {
      width: 100%;
      background: #000;

      iframe {
        display: block;
        border-radius: 16px;
      }
    }

    .video-controls {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .btn {
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 8px;
        height: 43px;
      }

      .btn-link {
        background: #2c407c;
        color: #ffffff;
        text-decoration: none;
        font-weight: normal;
        align-self: stretch;
        font-size: 1rem;

        &:hover {
          background: darken(#2c407c, 5%);
          color: #ffffff;
        }
      }

      .btn-primary {
        background: #ffffff;
        border: 1px solid #4e5361;
        color: #4e5361;
        font-weight: normal;
        font-size: 1rem;
        box-sizing: border-box;
        align-self: stretch;
        transition: all 0.2s ease;

        &:hover {
          background: #f0f0f0;
          color: #4e5361;
          border-color: #4e5361;
        }
      }
    }
  }
}
