.content-modal {
  .modal-content {
    border-radius: 20px;
    border-color: transparent;
    h4 {
      font-size: 1.375rem;
      font-weight: bold;
      text-transform: uppercase;
      color: black;
      .warning {
        color: #d81919;
        font-size: 1.125rem;
        text-decoration: underline;
      }
    }
    button.btn {
      font-size: 1.25rem;
    }
    .close {
      &:hover {
        cursor: pointer;
      }
    }
    button.cancel {
      background: transparent;
      border: none;
      font-weight: bold;
      text-decoration: underline;
      font-size: 1.125rem;
      &:hover {
        text-decoration: none;
      }
    }
    .disclaimer {
      font-size: 0.5rem;
      font-weight: bold;
      margin-top: 25px;
    }
  }
  .modal-dialog {
    max-width: 650px;
    margin-top: 120px;
  }
}
