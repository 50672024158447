.community-request-details {
  .community-request-header {
    background-color: var(--color-heading);
    border-radius: 16px;
    strong {
      line-height: 1.5rem;
    }
  }
  strong {
    color: var(--color-primary);
    font-size: 1.5rem;
    display: block;
    &.campaign-name {
      font-size: 1.125rem;
      span {
        color: #2e2e30;
        button {
          margin-left: 15px;
        }
      }
    }
  }
  .logo {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 50%;
  }
  dt {
    margin-top: 15px;
    font-size: 1.125rem;
    color: #19155e;
  }
  dd {
    font-size: 0.875rem;
    font-weight: normal;
  }
  button.chat-btn,
  button.print {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-radius: 100%;
    border: none;
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
  button.outline {
    background-color: transparent;
    color: #000 !important;
    width: 250px;
    padding: 6px 35px;
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: normal;
    border-radius: 50px;
    &.btn-danger {
      border: 3px solid #ff593f;
      &:hover {
        background-color: #ff593f;
      }
    }
    &.btn-primary {
      border: 3px solid #2f80ed;
      &:hover {
        background-color: #2f80ed;
      }
    }
    &:disabled {
      background-color: black;
      color: white !important;
      opacity: 1;
    }
    &:hover {
      color: white !important;
      box-shadow: 9px 6px 15px -5px rgba(0, 0, 0, 0.13) !important;
    }
  }
  .community-request-action {
    button {
      font-size: 1.5rem;
      width: 350px;
      max-width: 100%;
    }
  }
}
