.chat-panel {
  border-top: 1px solid #808080;
  .chat-id {
    font-size: 1rem;
    display: block;
    color: black;
  }
  .pn-msg-list {
    height: 300px !important;
    .pn-msg-list-scroller {
      scroll-snap-type: y proximity;
      .pn-msg-list__bottom-ref {
        scroll-snap-align: end;
      }
    }
  }
}
