.data-grid {
  .org-badge {
    .name {
      font-size: 1rem;
    }
  }
  &.coming-soon {
    .table-responsive {
      table {
        thead {
          th {
            font-weight: 500;
          }
        }
        tbody {
          display: none;
        }
      }
    }
  }
  h3 {
    font-size: 2.25rem;
    font-weight: bold;
    text-transform: capitalize;
  }

  button.btn-primary {
    background-color: #fff;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    &:hover {
      background-color: var(--color-primary) !important;
      color: white;
    }
  }
  .table-responsive {
    margin-bottom: 0;
    padding-bottom: 15px;
    table {
      tbody {
        tr {
          &:hover {
            background: #f0f8fd;
            transform: scale(1, 1);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
          }
          &.blur {
            filter: blur(5px);
            &:hover {
              background: initial;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
  .pagination {
    .page-link {
      &:focus,
      &:active {
        box-shadow: none;
        outline: 0;
        background-color: inherit;
      }
    }
  }
}
