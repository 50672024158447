.subscription-banner {
  background-color: #2563eb;
  border-radius: 20px;
  .btn-primary {
    background-color: #ffffff !important;
    color: #2563eb !important;
    border: 1px solid #2563eb !important;
    &:hover {
      background-color: darken($color: #ffffff, $amount: 10) !important;
    }
  }
}
