.community-requests-list {
  table {
    tbody {
      td {
        button.btn-primary {
          background-color: #fff;
          border: 1px solid #2f80ed;
          color: #2f80ed;
          min-width: 100%;
          &:hover,
          &:focus {
            background-color: #2f80ed !important;
            border: 1px solid #2f80ed;
            color: #fff;
          }
          &.pending {
            background-color: #43435c;
            border: #43435c;
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
  }
}
