.bids {
  .nav-tabs {
    margin: 30px 0 30px 0;
    .nav-item {
      border: 1px solid #c4c4c4;
      width: 200px;
      padding: 15px 0;
      margin: 0;
      border-radius: 7px;
      background-color: #f3f6fb;
      button {
        background-color: transparent;
        margin: 0 auto;
      }
    }
  }
}
