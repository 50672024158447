.tutorial-banner {
  background-color: #ffffff;
  color: #4e5361;
  margin-bottom: 2rem;
  position: relative;
  font-size: 0.875rem;
  padding: 16px 24px;
  box-shadow: 0px 8px 20px 0px #23262d20;
  border-radius: 16px;

  .banner-content {
    min-height: 112px;
    display: flex;
    flex-direction: column;
    .text {
      min-height: 40px;
    }
  }

  .close-button {
    position: absolute;
    top: 16px;
    right: 24px;
    font-size: 1.625rem;
    color: #666;
    cursor: pointer;
    line-height: 1;

    &:hover {
      color: #333;
    }
  }

  .video-thumbnail {
    position: relative;
    width: 130px;
    height: 112px;
    background-color: #f0f0f0;
    background-image: url("../assets/images/tutorial_thumbnail.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;

    .play-button {
      color: #2563eb;
      font-size: 1.2rem;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  h2 {
    font-size: 1.625rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 0;
    color: #2c2c3d;
    &:hover {
      cursor: text;
    }
  }

  p {
    font-size: 0.9rem;
  }
}

.watch-video-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.watch-video-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  isolation: isolate;
  width: fit-content;
  height: 43px;
  background: #fff9ec;
  border: 1px solid #ffc96d;
  border-radius: 16px;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #d19100;
  transition: all 0.2s ease;

  &:hover {
    background: darken(#fff9ec, 2%);
  }
}
