@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.dashboard {
  .actions {
    button {
      width: 270px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
