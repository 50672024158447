.user-info {
  strong {
    color: var(--color-darkbg-text);
    font-size: 1rem;
  }
  .title,
  .pronouns {
    font-size: 0.625rem;
    color: #778ab1;
  }
}
