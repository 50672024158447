button.back {
  color: black;
  background: transparent;
  border: none;
  text-decoration: none;
  font-size: 0.9375rem;
  font-weight: normal;
  margin: 9px 0;
  span {
    display: inline-block;
    margin-left: 5px;
  }
}
