/* Bootstrap colors */
$primary: #19155e;
$secondary: #c7c0c0;
$success: #69a509;
$info: #0b2d78;
$warning: #7a4f09;
$danger: #7a0c1f;

/* Custom colors */
$lightBg: #fefefe;
$lightBorder: #ffffff0d;
$darkBg: #2c2c3d;
$darkBgText: #c5d2ea;
$heading: #e6eefd;
$inputBorder: #adbbd4;
$navbarText: #2e2e30;

/* Spacing */
$content-x-padding: "60px";
