.sidebar-wrapper {
  &.closed {
    #sidebar {
      .sidebar-header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sidebar-content {
        a {
          &.top-level {
            &:after {
              margin-right: 24px;
              border-top-color: #2c2c3d;
            }
          }
        }

        .featured.nav-item {
          margin: 0;
          background-color: #2c2c3d;
        }
        .nav > .nav-item {
          width: 80px;
          .nav-link {
            &:after {
              margin-left: 22px;
            }
          }
          .dropdown-menu {
            margin-left: 4px;
          }
        }
      }
    }
  }
  #sidebar {
    background-color: var(--color-darkbg);
    color: var(--color-darkbg-text);

    .user-name {
      text-overflow: ellipsis;
    }
    .sidebar-header {
      justify-content: space-between;
      padding: 11px 34px 12px 34px;
      border-bottom: 1px solid #ffffff0d;
      .brand {
        strong {
          font-weight: 900;
          font-size: 0.8125rem;
        }
      }
      button {
        padding-right: 0;
        margin-right: 0;
        padding-left: 15px;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .sidebar-content {
      .nav-item {
        position: relative;
        &:hover {
          ul.dropdown-menu {
            display: block;
          }
        }
        &.featured {
          margin: 0 30px 15px 30px;
          background-color: white;
          border-radius: 20px;
          border-bottom: none;
          &:hover {
            border-width: 0;
            background-color: darken(white, 10%);
          }
          a.nav-link {
            color: #404040;
            border: none !important;
            font-size: 16px;
            &:hover,
            &.active {
              color: #404040;
              background-color: transparent;
            }
            span {
              text-align: center !important;
              display: inline-block;
              width: 100%;
            }
          }
        }
        ul.dropdown-menu {
          top: 0;
          left: 100%;
          border-radius: 8px;
          background-color: #373747;
          white-space: nowrap;
          .nav-link {
            &.active {
              background-color: inherit;
              text-decoration: underline;
            }
          }
        }
        .nav-link {
          color: var(--color-darkbg-text);
          &:hover {
            color: white;
            text-decoration: underline;
          }
          &.top-level {
            display: flex;
            padding: 15px 30px;
            font-size: 1.125rem;
            line-height: 1.125rem;
            font-weight: bold;
            border-top: 1px solid var(--color-lightborder);
            border-radius: 0;
            &:after {
              margin-left: auto;
              transform: rotate(-90deg);
              border-width: 0.4em;
            }

            img {
              align-self: center;
            }
            span {
              margin-left: 10px;
              display: inline-block;
            }
            &.active,
            &:hover {
              text-decoration: none;
              color: inherit;
              background-color: #ffffff0d;
              span {
                display: block;
              }
            }
          }
        }
      }
      &:last-child {
        border-bottom: 1px solid var(--color-lightborder) !important;
      }
    }
  }
}
