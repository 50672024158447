@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.org-profile {
  .back {
    margin: 0 0 10px 0;
    display: block;
  }
  .heading {
    h1 {
      font-size: 2rem;
      small {
        font-size: 0.875rem;
        color: #4d4d54;
        display: block;
        margin-top: 5px;
      }
    }
    button {
      width: 275px;
      margin-bottom: 15px;
    }
  }
  .cover {
    height: 167px;
    max-height: 167px;
    border-radius: 22px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center -200px;
    img {
      margin-top: 110px;
      margin-left: 35px;
    }
  }
  .org-info {
    margin-top: 70px;
    h2 {
      font-size: 2.25rem;
      font-weight: bold;
      color: #000;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    small {
      font-size: 1.125rem;
      font-weight: bold;
      color: #7e7e82;
    }
  }
  .links {
    a.btn-secondary {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: normal;
      color: #2d3e76;
      border: 1px solid #2d3e76;
      background-color: #fff;
      &:hover {
        background-color: #2d3e76;
        color: white;
      }
    }
    .socials {
      padding-top: 5px;
    }
  }
  .cause {
    margin-top: 70px;
    @include media-breakpoint-down(sm) {
      margin-top: 35px;
    }
    border: 1px solid #e6e6e9;
    padding: 30px 49px;
    font-size: 1rem;
    img {
      text-align: bottom;
    }
    h4 {
      text-transform: uppercase;
      font-size: 1.125rem;
      color: #7e7e82;
      margin-bottom: 5px;
      font-weight: bold;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
    }
    button {
      background-color: #fff;
      border: 1px solid var(--color-primary);
      color: var(--color-primary);
      font-weight: bold;
      &:hover {
        background-color: var(--color-primary);
        color: white;
      }
    }
  }
  .requests {
    h3 {
      color: #000;
      padding-left: 30px;
      font-weight: bold;
      font-size: 2.25rem;
    }
  }
  table {
    tbody {
      tr {
        td {
          .org-badge {
            min-width: 200px;
          }
        }
      }
    }
  }
}
