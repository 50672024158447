@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.mobile {
  background-color: #f0eeee;

  .container {
    @include media-breakpoint-up(md) {
      max-width: 900px;
    }
    padding: 30px 70px 0 70px;
    h1 {
      font-weight: 600;
    }
  }
}
