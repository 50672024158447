@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700;900&display=swap");
@import "./variables";

:root {
  --color-primary: #{$primary};
  --color-secondary: #{$secondary};
  --color-success: #{$success};
  --color-info: #{$info};
  --color-warning: #{$warning};
  --color-danger: #{$danger};
  --color-lightbg: #{$lightBg};
  --color-lightborder: #{$lightBorder};
  --color-darkbg: #{$darkBg};
  --color-darkbg-text: #{$darkBgText};
  --color-heading: #{$heading};
  --color-input-border: #{$inputBorder};
  --color-navbar-text: #{$navbarText};
  --content-x-padding: #{$content-x-padding};
}

// * {
//   outline: 1px solid limegreen;
// }

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

html,
body {
  font-family: "Roboto", sans-serif;
  --toastify-toast-width: 600px;
  font-size: 16px;
}

h1 {
  padding: 0 0 30px 0;
  font-size: 2.25rem;
  color: #212129;
  font-weight: 700;
}

h2 {
  font-size: 1.375rem;
  margin-top: 20px;
  color: var(--color-primary);
  font-weight: 700;
}
h3 {
  font-size: 2.25rem;
  font-weight: bold;
  text-transform: capitalize;
}
h4 {
  color: var(--color-primary);
}
.subheading {
  font-size: 0.75rem;
  padding-top: 5px;
}
.sublink {
  color: #000;
  &:hover {
    text-decoration: none;
  }
}
.layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  &:has(.sidebar-wrapper.closed) {
    grid-template-columns: 80px minmax(0, 1fr);
  }
  @include media-breakpoint-down(xl) {
    grid-template-columns: 80px minmax(0, 1fr);
  }
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.table-responsive {
  margin-bottom: 15px;
}
table.table {
  @include media-breakpoint-down(md) {
    width: max-content;
  }
  > :not(:first-child) {
    border-top: none;
  }
  margin-bottom: 0;
  padding-bottom: 0;
  thead {
    color: rgba(46, 46, 48, 0.9);
    background-color: $heading;
    tr {
      th {
        border: none;
        font-weight: 500;
        font-size: 1rem;
        padding: 15px 2px;
        display: table-cell;
        vertical-align: middle !important;
        color: black;

        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding-left: 25px;
        }
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
  tbody {
    border-top: none;
    font-size: 0.875rem;
    font-weight: normal;
    tr {
      td {
        display: table-cell;
        vertical-align: middle !important;
        padding-left: 0;
        &:first-child {
          padding-left: 25px;
        }
        &:hover {
          cursor: pointer;
        }

        button {
          padding: 7px 35px;
          border-radius: 26px;
          font-size: 0.875rem;
        }
      }
    }
  }
}

.text-primary {
  color: var(--color-primary);
}

form {
  label {
    color: #202224;
    font-weight: normal;
    font-size: 1rem;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="tel"],
  .form-select,
  textarea.form-control {
    border-radius: 10px;
    padding: 10px 15px 9px 15px;
    font-size: 0.875rem;
    &.form-control-sm {
      width: auto;
      display: inline;
      margin-left: 15px;
      padding: 3px 15px;
      border-radius: 0;
    }
  }
}
a.btn,
button.btn {
  border-radius: 20px;
  padding: 10px 35px;
  font-size: 1rem;
  font-weight: 700;
  &.btn-success {
    color: #fff;
  }
  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    outline: 0;
  }
  &.btn-light {
    color: #adbbd4;
  }
  &.btn-primary {
    &.reverse {
      background-color: var(--color-primary);
      color: white;
      &:hover {
        background-color: white;
        color: var(--color-primary);
      }
    }
    background-color: white;
    color: var(--color-primary);
    &:hover {
      background-color: var(--color-primary);
      color: white;
    }
    &.checked {
      background-color: var(--color-primary);
      color: white;
    }
    &:disabled {
      background-color: #c6c6c6;
      border: none;
      color: white;
      opacity: 1;
    }
  }
  &.btn-success {
    &:disabled {
      background-color: #c6c6c6;
      border: none;
      color: white;
      opacity: 1;
    }
  }
  &.btn-secondary {
    &:hover {
      background-color: darken(#cfc9c9, 60%);
      border: 1px solid darken(#cfc9c9, 60%);
      color: #fff;
    }
  }
}
main {
  margin-top: 0px;
  padding: 20px var(--content-x-padding);
  @include media-breakpoint-down(lg) {
    padding: 10px;
  }
}

/* Nav Tabs */
ul.nav-tabs {
  margin-bottom: 20px;
  border-bottom: 0;
  li {
    margin-right: 25px;
    .nav-link {
      padding: 0;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: var(--color-primary);
      &.active {
        color: var(--color-primary);
        border: 0;
        border-bottom: 3px solid var(--color-primary);
      }
    }
  }
}

/* Toast */
.Toastify {
  .Toastify__toast-container--top-center {
    margin-top: 60px;
  }
  .Toastify__toast-icon {
    width: 33px;
    margin-right: 20px;
  }
  .title {
    display: block;
    font-size: 1.25rem;
  }
  .Toastify__toast {
    border-radius: 15px;
  }
  .Toastify__toast--info {
    background-color: #98c2fa;
    color: #000;
  }
  .Toastify__toast--success {
    background-color: #c7ec68;
    color: #000;
  }
  .Toastify__toast--warning {
    background-color: #ffaa2b;
    color: #000;
  }
  .Toastify__toast--error {
    background-color: #b71f23;
    color: #fff;
    .Toastify__close-button {
      color: #fff;
    }
  }
  .Toastify__close-button {
    font-size: 1.25rem;
    opacity: 1;
    margin-right: 10px;
  }
}

.google-map {
  width: 100%;
  height: 60vh;
}
