.footer {
  background-color: #2c2c3d;
  padding-top: 45px;
  color: #c5d2ea;
  font-weight: normal;

  .brand {
    font-size: 1rem;
    font-weight: bolder;
    sup {
      font-size: 0.5rem;
    }
  }
  .columns > div {
    flex: 1;
    text-align: center;
  }
  a {
    color: #c5d2ea;
    &:hover,
    &:active,
    &:focus {
      color: white;
      text-decoration: underline;
    }
  }
  .links {
    padding-inline: var(--content-x-padding);
    display: flex;
    gap: 25px;
    a {
      display: block;
      padding: 0;
    }
  }
  .copyright {
    padding-inline: var(--content-x-padding);
    color: #c5d2ea;
    border-top: 1px solid #43435c;
  }
}
