.open-beacon {
  .community-request-header {
    label {
      display: block;
      font-size: 1.125rem;
      color: var(--color-primary);
      font-weight: bold;
    }
  }
}
