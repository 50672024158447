.settings-nav {
  background-color: #fafbfe;
  border-radius: 30px;
  padding: 10px;
  a.nav-link {
    width: auto;
    display: inline-block;
    color: #000;
    font-weight: normal;
    padding: 0;

    &.active {
      border-bottom: 2px solid #19155e;
    }
  }
}
